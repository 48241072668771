<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import HawkShare from '~/common/components/organisms/hawk-share.vue';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useFormPermissions } from '~/forms/composables/form-permissions.composable';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import DuplicateFormTemplate from './form-template-duplicate.vue';

const props = defineProps({
  is_form_builder_page: {
    type: Boolean,
    default: false,
  },
  is_form_v2: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['viewActivity', 'isShareLoading']);
const $t = inject('$t');
const route = useRoute();
const { share_access_levels } = useFormPermissions();
const form_template_detail_store = useFormTemplateDetailStore();
const common_store = useCommonStore();
const has_modify_access = form_template_detail_store?.form_template_detail?.can_modify_template;
const has_create_access = form_template_detail_store?.form_template_detail?.can_create_forms;
const is_workflow_builder = form_template_detail_store?.form_template_detail?.workflow;

// delete template
const { open: openDeletePopup, close: closeDeletePopup } = useModal({
  component: HawkDeletePopup,
  attrs: {
    header: $t('Delete Template'),
    match_text_header: `${$t('Type')} "${form_template_detail_store?.form_template_detail?.name || ''}" ${$t('to delete template')}`,
    match_text: `${form_template_detail_store?.form_template_detail?.name || ''}`,
    content: `${$t('Are you sure you want to delete')} ${form_template_detail_store?.form_template_detail?.name || ''}? ${$t('Deleting the template will also delete all its forms')}. ${$t('This action cannot be undone')}.`,
    onClose() {
      closeDeletePopup();
    },
    confirm: async () => {
      try {
        await form_template_detail_store.delete_form_template(route.params.template_uid);
        form_template_detail_store.form_template_track_events('Deleted');
        closeDeletePopup();
      }
      catch (err) {
        logger.log('🚀 ~ confirm: ~ err:', err);
        $toast({
          title: 'Something went wrong',
          text: 'Please try again',
          type: 'error',
        });
      }
    },
  },
});
async function duplicate_template(form_template_name) {
  const duplicate_form_payload = {
    name: form_template_name || `Copy of ${form_template_detail_store?.form_template_detail?.name}`,
    users: false,
    labels: false,
    schedule: false,
    targetElement: route.params?.asset_id
      ? {
          ...form_template_detail_store?.form_template_detail?.target_element,
          asset: route.params.asset_id,
          type: 'asset',
        }
      : form_template_detail_store?.form_template_detail?.target_element,
  };
  await form_template_detail_store?.duplicate_form_template({ body: { form: duplicate_form_payload }, uid: route.params.template_uid, attribute: 'duplicate' });
  form_template_detail_store.form_template_track_events('Duplicated');
}
function duplicate() {
  const { open, close } = useModal({
    component: DuplicateFormTemplate,
    attrs: {
      footer_buttons: [
        { color: 'default', type: 'default', text: 'Cancel', class: 'mr-2', click: () => close() },
        {
          color: 'primary',
          text: 'Duplicate Form',
        },
      ],
      disable_footer: true,
      onClose() {
        close();
      },
      async submit(data, form) {
        await duplicate_template(form.data.text);
        close();
      },
    },
  });
  return [{ label: $t('Duplicate'), on_click: open }];
}
function formatSharedata(data, obj) {
  data?.length && data?.forEach((item) => {
    obj[`${item.uid}`] = `${item?.access}`;
  });
  return obj;
}
async function onShare(data) {
  const load_data = {};
  load_data.members = formatSharedata(data?.users, {});
  load_data.teams = formatSharedata(data?.teams, {});
  const payload = {
    body: {
      ...load_data,
    },
  };
  await form_template_detail_store.update_form_details(payload);
  await form_template_detail_store.set_form_template({ id: route.params.template_uid });
  form_template_detail_store.form_template_track_events('Members updated');
}

const dropdown_settings_array = computed(() => {
  return [
    ...(props.is_form_v2
      ? [{
          label: 'Activity History',
          on_click: () => emit('viewActivity'),
        }]
      : []),
    { label: $t('Settings'), to: { name: 'forms-template-settings-general', params: { asset_id: route.params.asset_id } } },
    ...((has_create_access) ? duplicate() : []),
    {
      label: $t('Share'),
      on_click: () => {
        const { open: openShareModal, close: closeShareModal } = useModal({
          component: HawkShare,
          attrs: {
            onClose() {
              closeShareModal();
            },
            is_modal: true,
            access_levels: share_access_levels,
            members: common_store.filter_users(form_template_detail_store?.form_template_detail?.members),
            teams: common_store.filter_teams(form_template_detail_store?.form_template_detail?.members),
            async get_share_data(data) {
              try {
                emit('isShareLoading', true);
                await onShare(data);
                emit('isShareLoading', false);
              }
              catch (error) {
                emit('isShareLoading', false);
                logger.info('[DEBUG] form-template-detail-dropdown.vue::143\n', error);
              }
            },
          },
        });
        openShareModal();
      },
    },
    { label: $t('Delete'), on_click: openDeletePopup },
  ];
});

const get_builder = computed(() => {
  const is_published = form_template_detail_store?.form_template_detail?.status === 'published';
  if (is_workflow_builder)
    return { label: is_published ? $t('View workflow') : $t('Edit workflow'), to: { name: 'forms-template-workflow-builder' } };
  return { label: is_published ? $t('View template') : $t('Edit template'), to: { name: 'forms-template-builder' } };
});

const dropdown_settings = props.is_form_builder_page ? dropdown_settings_array.value : [get_builder.value, ...dropdown_settings_array.value];
</script>

<template>
  <div class="flex align-center">
    <hawk-menu v-if="has_modify_access" class="mx-2" :items="dropdown_settings" additional_trigger_classes="!ring-0 w-full">
      <template #trigger>
        <div class="flex cursor-pointer items-center rounded  hover:bg-gray-50">
          <hawk-button type="outlined" :icon="true">
            <IconHawkDotsVertical class="text-gray-700 w-5 h-5" />
          </hawk-button>
        </div>
      </template>
    </hawk-menu>
  </div>
</template>
