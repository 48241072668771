<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import ThermReportGraph from '~/therm/components/therm-report-graph.vue';
import { useThermStore } from '~/therm/store/therm.store';

const { $t } = useCommonImports();
const therm_store = useThermStore('therm_dashboard');

const temperature_graph_details = computed(() => {
  return {
    type: 'column2d',
    title: $t('Temperature Histogram'),
    description: $t('Graphical distribution of defects in relation to temperature differences.'),
    xAxisName: 'Temperature difference',
    yAxisName: 'No. of defects',
    plotToolText: '$yAxisName: $value',
    id: 'temperature_difference_container',
    hide_download: true,
    showValues: true,
  };
});
const temperature_graph_data = computed(() => {
  const temperature_difference = therm_store.report_defects
    .map(feature => feature.temperature_difference.toFixed(2))
    .filter(t => t >= 0)
    .sort((a, b) => a - b);

  const data = [];
  if (!temperature_difference.length)
    return { data };

  const range_step = 1;

  const min_value = Math.floor(temperature_difference[0]);
  const max_value = Math.ceil(temperature_difference[temperature_difference.length - 1]);

  const range_counts = {};

  let range_index = 0;
  for (let start_range = min_value; start_range < max_value; start_range++) {
    const end_range = start_range + range_step;
    const range_key = `[${start_range}, ${end_range}]`;

    range_counts[range_key] = 0;

    while (range_index < temperature_difference.length && temperature_difference[range_index] <= end_range) {
      if (temperature_difference[range_index] >= start_range) {
        range_counts[range_key]++;
      }
      range_index++;
    }
  }

  for (const temperature in range_counts) {
    data.push({
      label: temperature,
      value: range_counts[temperature],
      color: '#53B1FD',
    });
  }

  return { data };
});
</script>

<template>
  <div class="border rounded-xl h-[450px] p-4 my-4">
    <ThermReportGraph
      :graph_details="temperature_graph_details"
      :data="temperature_graph_data"
      :has_borders="false"
      height="370"
      class="overflow-hidden"
    />
  </div>
</template>
