import { defineAsyncComponent } from 'vue';
import HawkLoader from '~/common/components/atoms/hawk-loader.vue';
import ProfileSettings from '~/profile-settings/pages/profile-settings.vue';

const General = defineAsyncComponent({
  loader: () => '~/profile-settings/pages/profile-settings-general.vue',
  loadingComponent: HawkLoader,
});
const Notifications = defineAsyncComponent({
  loader: () => '~/profile-settings/pages/profile-settings-notifications.vue',
  loadingComponent: HawkLoader,
});
const Security = defineAsyncComponent({
  loader: () => '~/profile-settings/pages/profile-settings-security.vue',
  loadingComponent: HawkLoader,
});

const routes = [
  {
    path: '/profile-settings',
    component: ProfileSettings,
    children: [
      {
        path: '',
        name: 'profile-settings-general',
        component: General,
        meta: { title: 'General' },
      },
      {
        path: 'security',
        name: 'profile-settings-security',
        component: Security,
        meta: { title: 'Security' },
      },
      {
        path: 'notifications',
        name: 'profile-settings-notifications',
        component: Notifications,
        meta: { title: 'Notifications' },
      },
    ],
  },
];
export default routes;
