<script setup>
import parser from 'any-date-parser';
// --------------------------------- Imports -------------------------------- //
import { difference, groupBy, isNil, keyBy, uniq, uniqBy } from 'lodash-es';
import { Types } from '~/common/components/organisms/hawk-custom-fields/custom-fields.composable.js';
import { useCommonStore } from '~/common/stores/common.store';
import { useDocumentCrud } from '~/dms/composables/document-crud.composable';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';
import { useDocumentStore } from '~/dms/store/document.store';

// ---------------------------------- Props --------------------------------- //
defineProps({
  store: {
    type: Object,
    default: () => {},
  },
});

// ---------------------------------- Emits --------------------------------- //
const emit = defineEmits(['import-success']);

// ---------------------------- Injects/Provides ---------------------------- //
const $t = inject('$t');
const $services = inject('$services');
// ----------------------- Variables - Pinia - consts ----------------------- //
const document_store = useDocumentStore();
const document_crud = useDocumentCrud();
const dms_settings_store = useDMSSettingsStore();
const common_store = useCommonStore();
const category_by_name = keyBy(common_store.categories, 'name');

const custom_field_type_map = Object.values(Types).reduce((acc, type) => ({ ...acc, [type.value]: type }), {});
const member_by_email = keyBy(common_store.users, 'email');

function get_init_attributes(data = '') {
  return {
    is_disabled: false,
    is_valid: true,
    style: {
      backgroundColor: 'none',
    },
    translated_data: data,
    message: '',
  };
}

function get_create_attributes(data, message = '') {
  return {
    is_disabled: false,
    is_valid: true,
    style: {
      backgroundColor: '#3399ff', // Light blue,
    },
    translated_data: data,
    message,
  };
}

function checkEmptyAndReturnData(fn) {
  return data => !isNil(data) && data !== '' ? fn(data) : null;
}

function custom_fields_configuration(config) {
  const properties = {
    text: {
      label: $t('Maximum of 256 characters'),
      example: $t('Electrical'),
      formatData: checkEmptyAndReturnData((data) => {
        return data?.substring(0, 256);
      }),
      pre_hook: (colData) => {
        return colData.map((data) => {
          const text = data?.toString() || '';
          let { is_valid, style, message } = get_init_attributes(text?.substring(0, 256));
          if (typeof text === 'string' && text.length > 256) {
            ({ is_valid, style, message } = get_invalid_attributes(text, 'is invalid'));
          }
          return {
            data: data || '',
            properties: {
              is_valid,
              style,
              message,
            },
          };
        });
      },
    },
    number: {
      label: $t('Any numeric value'),
      example: 50,
      formatData: checkEmptyAndReturnData((data) => {
        return Number(data);
      }),
      pre_hook: (colData) => {
        return colData.map((data) => {
          let { is_valid, style, message } = get_init_attributes(data);
          const invalid_data = data && !Number.isFinite(Number(data));
          if (data && invalid_data) {
            ({ is_valid, style, message } = get_invalid_attributes(data, 'is invalid'));
          }
          return {
            data: data || '',
            properties: {
              is_valid,
              style,
              message,
            },
          };
        });
      },
    },
    yes_no_na: {
      label: $t('One of Yes, No, NA'),
      example: 'Yes',
      formatData: checkEmptyAndReturnData((data) => {
        const yes = ['yes', 'true', 'pass', '1'].includes(data?.toString().toLowerCase());
        const no = ['no', 'false', 'fail', '0'].includes(data?.toString().toLowerCase());
        const translated_data = yes ? 1 : no ? -1 : 0;
        const invalid_data = data && !yes && !no && !['na'].includes(data?.toString().toLowerCase());
        return invalid_data ? null : translated_data;
      }),
      pre_hook: (colData) => {
        return colData.map((data) => {
          const yes = ['yes', 'true', 'pass', '1'].includes(data?.toString()?.toLowerCase());
          const no = ['no', 'false', 'fail', '0'].includes(data?.toString()?.toLowerCase());
          const translated_data = yes ? 'Yes' : no ? 'No' : 'NA';
          let { is_valid, style, message } = get_init_attributes(data ? translated_data : '');
          const invalid_data = data && !yes && !no && !['na'].includes(data?.toString()?.toLowerCase());
          if (data && invalid_data) {
            ({ is_valid, style, message } = get_invalid_attributes(data, 'is invalid'));
          }
          return {
            data: isNil(data) ? '' : data,
            properties: {
              is_valid,
              style,
              message,
            },
          };
        });
      },
    },
    date: {
      label: $t('Date format → DD MMM YYYY'),
      example: '1 January 2020',
      formatData: checkEmptyAndReturnData((data) => {
        return parser.fromString(data).toISOString();
      }),
      pre_hook: (colData) => {
        return colData.map((data) => {
          let { is_valid, style, message } = get_init_attributes(data);
          const invalid_data = data && parser.attempt(data)?.invalid;
          if (data && invalid_data) {
            ({ is_valid, style, message } = get_invalid_attributes(data, 'is invalid'));
          }
          return {
            data: data || '',
            properties: {
              is_valid,
              style,
              message,
            },
          };
        });
      },
    },
    single_select: {
      label: $t(`One of `) + config.items.map(item => item.label).join(', '),
      example: config.items[0]?.label,
      formatData: checkEmptyAndReturnData((data) => {
        let uid;
        config.items.forEach((item) => {
          if (item.label === data) {
            uid = item.value;
          }
        });
        return uid;
      }),
      pre_hook: (colData) => {
        return colData.map((data) => {
          const labels = config.items.map(item => item.label);
          let { is_valid, style, message } = get_init_attributes(data);
          const invalid_data = data && !labels.includes(data);
          if (data && invalid_data) {
            ({ is_valid, style, message } = get_invalid_attributes(data, 'is invalid'));
          }
          return {
            data: data || '',
            properties: {
              is_valid,
              style,
              message,
            },
          };
        });
      },
    },
    multi_select: {
      label: $t(`One/more comma separated values -  `) + config.items.map(item => item.label).join(', '),
      example: config.items[0]?.label,
      formatData: checkEmptyAndReturnData((data) => {
        const values = data.split(', ');
        const uids = [];
        config.items.forEach((item) => {
          values.forEach((value) => {
            if (value === item.label) {
              uids.push(item.value);
            }
          });
        });
        return uids;
      }),
      pre_hook: (colData) => {
        return colData.map((data) => {
          const labels = config.items.map(item => item.label);
          let { is_valid, style, message } = get_init_attributes(data);
          const invalid_data = data && !data.split(', ').every(item => labels.includes(item));
          if (data && invalid_data) {
            ({ is_valid, style, message } = get_invalid_attributes(data, 'is invalid'));
          }
          return {
            data: data || '',
            properties: {
              is_valid,
              style,
              message,
            },
          };
        });
      },
    },
    members: {
      label: $t('Existing user’s email address'),
      example: 'john@example.com',
      formatData: checkEmptyAndReturnData((data) => {
        const members_list = data?.split(',').map(member => member.trim());

        return members_list.map((member) => {
          return {
            uid: member_by_email[member]?.uid,
            name: member_by_email[member]?.name,
            member: true,
          };
        });
      }),
      pre_hook: (colData) => {
        return colData.map((data) => {
          let { is_valid, style, message } = get_init_attributes(data);
          if (data) {
            const members_list = data?.split(',').map(member => member.trim());
            for (const member of members_list) {
              if (member && !member_by_email[member]) {
                ({ is_valid, style, message } = get_invalid_attributes(data, 'is invalid'));
                break;
              }
            }
          }
          return {
            data: data || '',
            properties: {
              is_valid,
              style,
              message,
            },
          };
        });
      },
    },
  };
  return properties[config.type];
}

const custom_fields = (dms_settings_store.custom_fields?.filter(field => field.active) || []).map(field => ({
  field: field.uid,
  label: field.label,
  data_type: 'text',
  data_format: custom_field_type_map[field.type]?.label || field.type,
  required: false,
  description: custom_fields_configuration(field)?.label,
  example: custom_fields_configuration(field)?.example,
  preHook: custom_fields_configuration(field)?.pre_hook ? colData => custom_fields_configuration(field)?.pre_hook?.(colData) : null,
  formatData: custom_fields_configuration(field)?.formatData,
}));
// ------------------- Variables - Local - consts and lets ------------------ //

const legends = {
  error: {
    style: {
      backgroundColor: 'red', // Light red
      color: 'white',
    },
    label: $t('Required/Invalid value'),
    description: $t('The data is either invalid/duplicate or can\'t be modified.'),
  },
};

function get_invalid_attributes(data, message = '') {
  return {
    is_disabled: false,
    is_valid: false,
    style: legends.error.style,
    translated_data: data,
    message,
  };
}

// ---------------------- Variables - Local - refs & reactives --------------------- //

const columns = ref([
  {
    field: 'folder_name',
    label: $t('Folder name'),
    data_type: 'text',
    data_format: 'Text',
    required: true,
    description: $t('Name of the folder to import the documents'),
    example: 'Civil documents',
    preHook: async (colData) => {
      return colData.map((data) => {
        let { is_valid, style, message } = getInitAttributes(data);
        const invalid_characters_str = '\\/:*?\'\'<>|';
        const folder_name_regex = /^[^\\:*?'"<>|]+$/;

        if (!folder_name_regex.test(data))
          ({ is_valid, style, message } = getInvalidAttributes(data, `${$t('A folder name can not contain any of the following characters')}: ${invalid_characters_str}`));

        return {
          data,
          properties: {
            is_valid,
            message,
            style,
          },
        };
      });
    },
  },
  {
    field: 'document_number',
    label: $t('Document number'),
    data_type: 'text',
    data_format: 'Text',
    required: true,
    description: $t('Unique document number'),
    example: 'NLB001-E59R',
    preHook: async (colData) => {
      return colData.map((data) => {
        let { style, message } = get_init_attributes(data);
        const has_duplicate_numbers = colData.filter(col => col === data).length > 1;
        if (has_duplicate_numbers) {
          ({ style, message } = getInvalidAttributes(data, `${$t('Not a unique number')}: ${data}`));
        }
        return {
          data,
          properties: {
            is_valid: true,
            style,
            message,
          },
        };
      });
    },
  },
  {
    field: 'document_name',
    label: $t('Document name'),
    data_type: 'text',
    data_format: 'Text',
    required: true,
    description: $t('Name of the document'),
    example: 'DC layout',
  },
  {
    field: 'category',
    label: $t('Category'),
    data_type: 'text',
    data_format: 'Text',
    required: true,
    description: $t('Category of the document'),
    example: 'Electrical',
    preHook: async (colData) => {
      return colData.map((data) => {
        let { style, message } = get_init_attributes(data);
        if (!category_by_name[data]) {
          ({ style, message } = get_create_attributes(data, 'New category will be created'));
        }
        return {
          data,
          properties: {
            is_valid: true,
            style,
            message,
          },
        };
      });
    },
    postHook: async (colData) => {
      const category_names = common_store.categories.map(category => category.name);
      const new_categories = uniq(difference(colData, category_names))
        .filter(category => category)
        .map((category) => {
          return { name: category };
        });

      if (new_categories.length) {
        const { data } = await common_store.update_data({
          type: 'add',
          data: { categories: new_categories },
          service: 'categories',
          append_data: false,
          response_key: 'categories',
          state_prop: 'categories_map',
          update_state: false,
        });
        await common_store.update_global_data({ categories: data?.categories.map(category => category.uid) || [] });
      }

      return colData.map((data) => {
        return {
          data,
          properties: {
            is_valid: true,
            style: {
              backgroundColor: 'none',
            },
            message: '',
          },
        };
      });
    },
  },
  ...custom_fields,
]);

const schema = reactive({
  legends,
  columns: [...columns.value],
  methods: {
    callback: async (data) => {
      const folders_created = await createFolders(data);
      const folders_created_map = keyBy(folders_created, 'name');

      function getFieldValues(row) {
        const field_values = [];
        custom_fields.forEach((field) => {
          field_values.push({ field: field.field, value: field.formatData ? field.formatData(row[field.field]) : row[field.field] });
        });
        return field_values.filter(field_value => !isNil(field_value.value));
      }

      const get_category_uid = category_name => common_store.categories.find(category => category.name === category_name)?.uid;

      const files_to_add = data.map(item => ({
        name: item.document_name,
        category: get_category_uid(item.category),
        merge: true,
        number: item.document_number,
        is_placeholder: true,
        target_element: folders_created_map[item.folder_name].element,
        folder_uid: folders_created_map[item.folder_name].uid,
        asset: folders_created_map[item.folder_name].asset,
        field_values: getFieldValues(item),
      }));

      try {
        await $services.documents.post({
          body: {
            files: {
              add: files_to_add,
            },
          },
        });
      }
      catch (e) {
        logger.log(e);
      }

      await document_store.set_hierarchy();
    },
  },
});

// --------------------------- Computed properties -------------------------- //

// -------------------------------- Functions ------------------------------- //
async function createFolders(data) {
  let response = [];
  const common_payload = document_crud.getCommonPayload();
  const unique_folders = uniqBy(data, folder => folder.folder_name);
  const existing_folder_map_by_name = keyBy(document_store.folders, 'name');
  const folder_groups = groupBy(unique_folders, (folder) => {
    if (folder.folder_name.includes('/'))
      return 'hierarchy_folders';

    return existing_folder_map_by_name[folder.folder_name]?.uid ? 'existing_folders' : 'non_existing_folders';
  });

  const folders_to_create = (folder_groups.non_existing_folders || []).map(folder => ({ ...common_payload, name: folder.folder_name }));
  const existing_folders_data = (folder_groups.existing_folders || []).map(folder => existing_folder_map_by_name[folder.folder_name]);
  const hierarchy_folders_to_create = (folder_groups.hierarchy_folders || []).map(folder => (
    {
      ...common_payload,
      name: folder.folder_name,
      path: `${folder.folder_name}/${folder.document_name}`,
    }
  ));

  try {
    let normal_folders = [];
    let hierarchy_folders = [];

    if (folders_to_create.length) {
      const { data } = await document_store.crud_documents({
        request: {
          body: {
            folders: {
              add: folders_to_create,
            },
          },
        },
      });
      normal_folders = data.documents.folders.added || [];
    }

    if (hierarchy_folders_to_create.length) {
      // If folder name is 'F4/F4.1/F4.1.1' then create folder hierarchy
      const { data } = await document_store.create_folder_structure({
        files: hierarchy_folders_to_create.map(folder => ({ path: folder.path })),
        ...common_payload,
      });

      const hierarchy_folders_map = keyBy(hierarchy_folders_to_create, 'path');

      hierarchy_folders = Object.keys(data).map(hierarchy_path =>
        ({
          ...hierarchy_folders_map[hierarchy_path],
          uid: data[hierarchy_path],
          element: hierarchy_folders_map[hierarchy_path]?.target_element,
        }),
      );
    }

    response = [...normal_folders, ...hierarchy_folders];
  }
  catch (e) {
    logger.error(e);
  }

  return [...response, ...existing_folders_data];
}

function getInitAttributes(data = '') {
  return {
    is_disabled: false,
    is_valid: true,
    style: {
      backgroundColor: 'none',
    },
    translated_data: data,
    message: '',
  };
}

function getInvalidAttributes(data, message = '') {
  return {
    is_disabled: false,
    is_valid: false,
    style: legends.error.style,
    translated_data: data,
    message,
  };
}
</script>

<template>
  <csv-importer
    :schema="schema"
    :automap_columns="true"
    :generate_sample_file_name="() => 'Documents'"
    @import-success="emit('import-success')"
  />
</template>
