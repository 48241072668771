<script setup>
// --------------------------------- Imports -------------------------------- //
import { debounce } from 'lodash-es';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

import TaskPriority from '~/tasks/components/atoms/task-priority.vue';
import FormStatus from '~/forms/atoms/form-status.vue';
import FormsSearchAndFilter from '~/forms/components/forms-search-and-filter.vue';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';

import { useFormDetailStore } from '~/forms/store/form-detail.store.js';

// ---------------------------------- Props --------------------------------- //

// ---------------------------------- Emits --------------------------------- //

// ---------------------------- Injects/Provides ---------------------------- //

// ----------------------- Variables - Pinia - consts ----------------------- //
const form_detail_store = useFormDetailStore();

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //
const { $t, $date, $date_relative, $services, $toast, auth_store, common_store, route, router, $track_event } = useCommonImports();

// ------------------------ Variables - Local - refs ------------------------ //
const loading = ref(false);
const search = ref('');
const applied_filters = ref(null);
const available_forms = ref([]);
const total_form_count = ref(0);

// ---------------------- Variables - Local - reactives --------------------- //
const table_state = reactive({
  page_size: 25,
  page_number: 1,
  table_loading: false,
  table_instance: null,
});

// --------------------------- Computed properties -------------------------- //
const active_tab = computed(() => route.query?.active || 'pending');

const columns = computed(() => [
  {
    header: $t('Form name'),
    accessorKey: 'name',
    id: 'name',
    size: 200,
    enableResizing: false,
  },
  {
    header: $t('Template'),
    accessorKey: 'template',
    id: 'template',
    size: 200,
    enableResizing: false,
    enableSorting: false,
  },
  ...(active_tab.value === 'pending'
    ? [
        {
          header: $t('Priority'),
          accessorKey: 'priority',
          id: 'priority',
          size: 100,
          enableResizing: false,
          enableSorting: false,
        },
        {
          header: $t('Reviewers/Approvers'),
          accessorKey: 'assignees',
          id: 'assignees',
          size: 100,
          enableResizing: false,
          enableSorting: false,
        },
        {
          header: $t('Due date'),
          accessorKey: 'due_date',
          id: 'due_date',
          size: 100,
          enableResizing: false,
        },
        {
          header: $t('Step'),
          accessorKey: 'status',
          id: 'status',
          enableResizing: false,
          enableSorting: false,
        },
      ]
    : [
        {
          header: $t('Reviewed/Approved on'),
          accessorKey: 'reviewed_date',
          id: 'reviewed_date',
          size: 100,
          enableResizing: false,
        },
        {
          header: $t('Review status'),
          accessorKey: 'review_status',
          id: 'review_status',
          size: 100,
          enableResizing: false,
          enableSorting: false,
        },
      ]),
]);

const tabs = computed(() => [{
  uid: 'pending',
  label: 'Pending approvals',
  to: { name: 'forms-requests', params: { asset_id: route.params.asset_id }, query: { active: 'pending' } },
},
{
  uid: 'completed',
  label: 'Completed approvals',
  to: { name: 'forms-requests', params: { asset_id: route.params.asset_id }, query: { active: 'completed' } },
}]);

const has_filter_applied = computed(() => !!applied_filters.value || search.value);
const has_data = computed(() => !!available_forms.value.length);
const has_no_results = computed(() => !has_data.value && has_filter_applied.value);
const quick_filters = computed(() =>
  active_tab.value === 'completed' ? ['templates', 'reviewed_date'] : ['templates', 'priority', 'approval_due_date'],
);

// -------------------------------- Functions ------------------------------- //

async function onFilterApply({ filters, user_filter }) {
  applied_filters.value = { filters, user_filter };

  await getData({});
}

function onSearch(val) {
  search.value = val;
}

async function getData(options) {
  const { extra_filters } = options;

  table_state.table_loading = true;
  if (options?.pagination_state?.pageIndex && options?.pagination_state?.pageSize) {
    table_state.page_number = options?.pagination_state.pageIndex || table_state.page_number;
    table_state.page_size = options?.pagination_state.pageSize || table_state.page_size;
  }

  try {
    const { data, headers } = await $services.forms.post({
      attribute: 'list-requests',
      body: {
        filters: {
          templates: [],
          status: 'published',
          approval_status: active_tab.value,
          ...(search?.value ? { q: search.value } : {}),
          ...(options?.sort_by
            ? { sort: options.sort_by.desc ? `-${options.sort_by.id}` : options.sort_by.id }
            : {}),
          ...(
            applied_filters.value
              ? {
                  advanced_filters: { logic: { type: 'AND' }, rules: applied_filters.value.filters },
                  [applied_filters.value.user_filter.value]: !!applied_filters.value.user_filter.value,
                }
              : {}
          ),
          ...extra_filters,
        },
      },
    });

    available_forms.value = data.forms;
    total_form_count.value = headers['x-total-count'];
  }
  catch (e) {
    logger.error(e);
  }
  table_state.table_loading = false;
}

const debouncedGetData = debounce(getData, 500);

form_detail_store.$onAction(async ({ args, name, after }) => {
  after(() => {
    if (['approve_form'].includes(name))
      getData({});
  });
});

function openRequestForm(row) {
  if (active_tab.value === 'completed' && row.history_id)
    router.push({ query: { form: btoa(JSON.stringify({ form_uid: row.uid })), submission_history: row.history_id } });

  if (active_tab.value === 'pending')
    router.push({ query: { form: btoa(JSON.stringify({ form_uid: row.uid })) } });
}

// -------------------------------- Watchers -------------------------------- //
watch(() => search?.value?.length, () => {
  debouncedGetData({});
});
watch(() => active_tab?.value, () => {
  loading.value = true;
  applied_filters.value = null;
  available_forms.value = [];
  search.value = '';
  getData({});
  loading.value = false;
});

// ----------------------------- Lifecycle Hooks ---------------------------- //
onMounted(async () => {
  loading.value = true;
  await getData({});
  loading.value = false;
});
</script>

<template>
  <div>
    <HawkPageHeader :title="$t('Requests')" class="-mx-4">
      <template #left>
        <hawk-page-header-tabs :tabs="tabs" :active_item="active_tab" />
      </template>
    </HawkPageHeader>
    <div>
      <FormsSearchAndFilter :key="active_tab" :quick_filters="quick_filters" @search="onSearch" @apply="onFilterApply" />
      <div v-if="!has_data && (loading || table_state.table_loading)">
        <hawk-loader />
      </div>
      <HawkIllustrations v-else-if="has_no_results" type="no-results" for="forms" />
      <HawkIllustrations v-else-if="!has_data" variant="default" type="no-data" for="forms" />
      <TableWrapperVue v-else class="max-w-[calc(100vw-80px)] !max-h-[calc(100vh-65px)]" container_class="mt-0">
        <HawkTable
          :key="active_tab + table_state.page_number" class="overflow-auto" :is_loading="table_state.table_loading"
          :pagination_config="{ totalRows: total_form_count, pageIndex: table_state.page_number, pageSize: table_state.page_size }"
          :data="available_forms" :columns="columns" :is_gapless="true"
          :disable_resize="true" :show_menu_header="false" :manual_pagination="true"
          stick_header_offset="0"
          :manual_sorting="true" :table_header_sticky="false" @pagination="getData($event)" @sort="getData($event)"
          @tableInstance="table_state.table_instance = $event" @row-clicked="openRequestForm"
        >
          <template #name="form">
            <div class="whitespace-nowrap">
              <hawk-text :length="60" class="text-gray-900 font-medium" :content="form.data.getValue()" />
            </div>
          </template>
          <template #template="template">
            <div class="whitespace-nowrap">
              <hawk-text :length="60" class="text-gray-600 font-medium" :content="template.data.getValue()?.name" />
            </div>
          </template>
          <template #assignees="assignees">
            <HawkMembers v-if="assignees.data.getValue()?.length" :members="assignees.data.getValue()" type="badge" />
            <span v-else>-</span>
          </template>
          <template #priority="priority">
            <TaskPriority v-if="priority.data.getValue()" :priority="form_detail_store.priority_map[priority.data.getValue()].value" />
            <p v-else>
              -
            </p>
          </template>
          <template #due_date="due_date">
            <span
              class="text-sm whitespace-nowrap"
              :class="[due_date.data.getValue() && new Date(due_date.data.getValue()) <= new Date() ? 'text-[#B42318]' : 'text-[#475467]']"
            >
              {{ due_date.data.getValue() ? $date(due_date.data.getValue(), 'DD MMMM YYYY') : '-' }}
            </span>
          </template>
          <template #reviewed_date="reviewed_date">
            <span
              class="text-sm whitespace-nowrap text-[#475467]"
            >
              {{ reviewed_date.data.getValue() ? $date(reviewed_date.data.getValue(), 'DD MMMM YYYY hh:mm A') : '-' }}
            </span>
          </template>
          <template #status="status">
            <FormStatus :form="status.data.row.original" />
          </template>
          <template #review_status="review_status">
            <HawkBadge :color="review_status.data.getValue()?.type === 'approve' ? 'green' : 'red'" type="light">
              {{ review_status.data.getValue()?.status }}
            </HawkBadge>
          </template>
          <template #context_menu="form">
            <slot name="context_menu" :element="form" />
          </template>
        </HawkTable>
      </TableWrapperVue>
    </div>
  </div>
</template>
